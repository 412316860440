import React from 'react';
import Button from './Button';
import { useNavigate } from 'react-router-dom';
import { PortalButtonGlimmer } from '../components/PortalGlimmerElements';
import { PageHeaderGlimmer } from '../tenant_portal/PageHeader';
import { OauthProvider } from '../components/Constants';
import layoutStyles from '../../../stylesheets/components/layout.module.scss';
import { getProviderLogoPath } from '../components/Tools';
import Alert from '../components/Alert';

interface OptionsProps {
  emailPath: string;
  activationKey?: string;
}

const Options = ({ emailPath, activationKey }: OptionsProps) => {
  const navigate = useNavigate();
  const handleLoginButton = (provider: OauthProvider) => {
    if (provider === OauthProvider.EMAIL) {
      navigate(emailPath);
      return;
    }
    window.location.href = `${RAILS_BASE_URL}/session_transfers/oauth_redirect?provider=${provider}${
      activationKey ? `&login_activation_key=${activationKey}` : ''
    }`;
  };

  return (
    <>
      {preloadedErrors && <Alert severity="error">{preloadedErrors}</Alert>}
      <Button
        onClick={() => handleLoginButton(OauthProvider.GOOGLE)}
        logoPath={getProviderLogoPath(OauthProvider.GOOGLE)}
      >
        Continue with Google
      </Button>
      <Button
        onClick={() => handleLoginButton(OauthProvider.APPLE)}
        logoPath={getProviderLogoPath(OauthProvider.APPLE)}
      >
        Continue with Apple
      </Button>
      <Button
        onClick={() => handleLoginButton(OauthProvider.FACEBOOK)}
        logoPath={getProviderLogoPath(OauthProvider.FACEBOOK)}
      >
        Continue with Facebook
      </Button>
      <Button
        onClick={() => handleLoginButton(OauthProvider.MICROSOFT)}
        logoPath={getProviderLogoPath(OauthProvider.MICROSOFT)}
      >
        Continue with Microsoft
      </Button>
      <Button
        onClick={() => handleLoginButton(OauthProvider.EMAIL)}
        logoPath={getProviderLogoPath(OauthProvider.EMAIL)}
        id="email-login-button"
      >
        Continue with Email
      </Button>
    </>
  );
};

export const OptionsGlimmer = () => {
  return (
    <>
      <PageHeaderGlimmer />
      <PortalButtonGlimmer />
      <PortalButtonGlimmer />
      <PortalButtonGlimmer />
      <PortalButtonGlimmer />
      <PortalButtonGlimmer />
    </>
  );
};

export default Options;
